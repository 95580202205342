<template>
  <div class="page">
    <el-row style="width: 100%">
      <el-col :span="4">
        <div class="userView">
          <div class="userInfo">
            <el-image class="userAvatar" :src="commentObj.user_obj.pic" width="80" height="80"></el-image>
            <sapn class="userName">{{ commentObj.user_obj.name }}</sapn>
          </div>
          <div class="goodsInfo">
            <div class="infoItem">
              <span class="infoTitle">商品：</span>
              <span>{{ commentObj.goods_obj.name }}</span>
            </div>
            <div class="infoItem">
              <span class="infoTitle">订单号：</span>
              <span>{{ commentObj.order_obj.order_sn }}</span>
            </div>
            <div class="infoItem">
              <span class="infoTitle">配送评分：</span>
              <el-rate
                  v-model="commentObj.deliver_rank"
                  disabled>
              </el-rate>
            </div>
            <div class="infoItem">
              <span class="infoTitle">商品评分：</span>
              <el-rate
                  v-model="commentObj.goods_rank"
                  disabled>
              </el-rate>
            </div>
            <div class="infoItem">
              <span class="infoTitle">服务评分：</span>
              <el-rate
                  v-model="commentObj.service_rank"
                  disabled>
              </el-rate>
            </div>
            <div class="infoItem">
              <span class="infoTitle">日期：</span>
              <span>{{ commentObj.create_at | formatDate }}</span>
            </div>
            <div class="infoItem">
              <span class="infoTitle">IP地址：</span>
              <span>{{ commentObj.ip_address }}</span>
            </div>
          </div>
        </div>
      </el-col>
      <el-col :span="16" :offset="1">
        <div class="reviewView">
          <div class="reviewInfo">
            <div class="title">
              <i class="el-icon-reading"></i>
              <span>评价内容</span>
            </div>
            <div class="reviewContent">{{ commentObj.content }}</div>
            <div class="reviewImages">
              <el-image class="reviewImage" v-for="(item,index) in commentObj.img" :src="item" :key="index"
                        :preview-src-list="commentObj.img"></el-image>
            </div>
          </div>
          <div class="replyView">
            <div class="title">
              <i class="el-icon-chat-line-square"></i>
              <span>回复</span>
            </div>
            <el-form ref="replyForm" :model="reply" label-width="100px" label-position="left" :rules="replyRules">
              <el-form-item label="回复内容" prop="content">
                <el-input type="textarea" v-model="reply.content" :rows="4" :resize="false"></el-input>
              </el-form-item>
            </el-form>
            <el-button type="primary" @click="replySubmit" style="margin-top: 10px;margin-left: 100px">提交</el-button>
          </div>
        </div>
      </el-col>
    </el-row>


  </div>
</template>

<script>
import {apiGetCommentDetail, apiReplyComment} from "@/request/api";

export default {
  name: "goodsReview",
  data() {
    return {
      id: this.$route.query.id,
      commentObj: {},
      reply: {
        content: ""
      },
      replyRules: {
        content: [
          {required: true, message: "请输入回复内容", trigger: "blur"}
        ]
      },
    }
  },
  filters: {
    formatDate(time) {
      var date = new Date(time * 1000);
      return date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate() + " " + date.getHours() + ":" + date.getMinutes() + ":" + date.getSeconds();
    }
  },
  created() {
    this.getData();
  },
  methods: {
    getData() {
      apiGetCommentDetail({id: this.id}).then(res => {
        if (res.code == 200) {
          this.commentObj = res.data;
          this.reply.content = res.data.reply_content;
        }
      })
    },
    replySubmit() {
      apiReplyComment({id: this.id, reply_content: this.reply.content}).then(res => {
            if (res.code == 200) {
              this.$message.success("回复成功");
            }
          }
      )
    }
  }
}
</script>

<style lang="less" scoped>
.page {
  background-color: #FFF;
  //padding: 20px;
  margin: 20px;
  margin-bottom: 80px;
  display: flex;
}

.userView {
  display: flex;
  flex-direction: column;
  align-items: center;
  //background-color: #fafafa;
  border-right: 1px solid #eee;
  padding: 0 30px;
  box-sizing: border-box;
  height: 100%;

  .userInfo {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 40px;
    margin-bottom: 20px;

    .userAvatar {
      width: 80px;
      height: 80px;
      border-radius: 50%;
      margin-bottom: 20px;
      border: 2px solid #FFF;
    }
  }

  .goodsInfo {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 20px;

    .infoItem {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-bottom: 10px;
      font-size: 14px;
    }
  }
}

.reviewView {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 20px;
  width: 100%;

  .title {
    display: flex;
    align-items: flex-end;
    color: #99a9bf;
    width: 100%;
    border-bottom: 1px solid #eee;
    padding-bottom: 10px;
    margin-bottom: 20px;

    i {
      font-size: 30px;
      margin-right: 10px;
    }
  }

  .reviewInfo {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 40px;
    margin-bottom: 20px;
    width: 100%;

    .reviewContent {
      width: 100%

    }

    .reviewImages {
      width: 100%;
      margin-top: 20px;
      display: flex;
      flex-wrap: wrap;

      .reviewImage {
        width: 100px;
        height: 100px;
        margin-right: 10px;
      }
    }
  }

  .replyView {
    width: 100%;
  }
}
</style>